import React, { useMemo } from 'react';
import {
  Container,
  Subtitle,
  Row,
  VaccineDetails,
  VaccineTitle,
  VaccineNotAdministrateTitle,
  VaccineSubtitle,
  VaccineDate,
  VaccineIcon,
  SyringeIcon,
} from './Components';
import { Box, Typography, makeStyles } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';

const isApplicationPastDate = (birthday, startDays) => {
  const birthdayMoment = moment(birthday, "DD/MM/YYYY");
  const nowMoment = moment();
  const shoudHaveBeenApplied = birthdayMoment
    .add(startDays || 0, 'days')
    .isBefore(nowMoment);
  return shoudHaveBeenApplied;
};

const isNoInfo = (birthday, userProfileCreationDate, applicationEndDays) => {
  return (
    moment(userProfileCreationDate).diff(birthday, 'd') >= applicationEndDays
  );
};

const useStyles = makeStyles((theme) => ({
  accordion: {
    border: 'none',
    padding: '0',
    boxShadow: 'none',
    '&::before': {
      backgroundColor: 'transparent',
    },

    '& .MuiAccordionSummary-content.Mui-expanded': {
      margin: '0px',
    },
  },

  details: {
    padding: '0px 32px',
  },

  detailsWithCode: {
    padding: '0px',
  },

  summary: {
    margin: '0px',
  },
}));

const YELLOW_GROUP = '#F9E708';
const RED_GROUP = '#DB0623';
const GREY_GROUP = '#A4A4A4';
const GREEN_GROUP = '#00A859';
const BLUE_GROUP = '#ACD7FE';

const VaccineTable = ({
  vaccines,
  birthday,
  type,
  search,
}) => {
  const { t } = useTranslation('vaccinesPage');
  const classes = useStyles();

  const createRows = () => {
    const vaccinesComponent = [];
    (vaccines || []).forEach((vaccine, index) => {
      let status;
      if (type === 'vaccine') {
        if (vaccine.administrated) {
          status = GREEN_GROUP;
        } else {
          if (isApplicationPastDate(birthday, vaccine.applicationEndDays)) {
            status = isNoInfo(
              vaccine.userBD,
              vaccine.userProfileCreationDate,
              vaccine.applicationEndDays
            )
              ? BLUE_GROUP
              : RED_GROUP;
          } else if (
            isApplicationPastDate(birthday, vaccine.applicationStartDays)
          ) {
            status = YELLOW_GROUP;
          } else {
            status = GREY_GROUP;
          }
        }
        // Antibody or tests
      } else {
        status = vaccine.administrated ? GREEN_GROUP : GREY_GROUP;
      }

      let date, lotNumber, producer;

      if (vaccine.administrated) {
        date = vaccine.adminDetails.date;
        lotNumber = vaccine.adminDetails.lotNumber;
        producer = vaccine.adminDetails.pharma;
      }

      vaccinesComponent.push(
        <Accordion key={`${vaccine.id} ${vaccine.name}`} className={classes.accordion}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            classes={{ content: classes.summary }}
          >
            <Row>
              <VaccineIcon>
                <SyringeIcon status={status} />
              </VaccineIcon>
              <VaccineDetails>
                {vaccine.administrated ? (
                  <VaccineTitle>{vaccine.name}</VaccineTitle>
                ) : (
                  <VaccineNotAdministrateTitle>{vaccine.name}</VaccineNotAdministrateTitle>
                )}

                {vaccine.administrated && (
                  <VaccineSubtitle>
                    {vaccine.adminDetails.wasTranscribed ? (
                      <>
                        <span
                          style={{
                            display: 'block',
                            color: 'blue',
                            fontWeight: 'bold',
                            margin: 0,
                          }}
                        >
                          {t('TRANSCRIBED_BY')}:
                        </span>
                        {' '}
                        {vaccine.adminDetails.transcribedBy}
                      </>
                    ) : (
                      <>
                        {t('APPLIED_BY')}
                        {' '}
                        {vaccine.adminDetails.hcpFullName}
                      </>
                    )}
                  </VaccineSubtitle>
                )}
              </VaccineDetails>
            </Row>
          </AccordionSummary>
          {vaccine.administrated ?
            vaccine.adminDetails.wasTranscribed ? (
              <AccordionDetails className={classes.details}>
                <div>
                  <VaccineDate>
                    <VaccineSubtitle alignText="right">
                      <b>
                        {t('TRANSCRIPTION_DATE_TEXT')}:
                        {' '}
                      </b>
                      {date}
                    </VaccineSubtitle>
                    <VaccineSubtitle alignText="right">
                      <b>
                        {t('DATE')}:
                        {' '}
                      </b>
                      {vaccine.adminDetails.transcription.date
                        ? vaccine.adminDetails.transcription.date
                        : t('NOT_AVAILABLE_INFO')}
                    </VaccineSubtitle>
                    <VaccineSubtitle alignText="right">
                      <b>
                        {t('CARD_LOT_NUMBER')}:
                        {' '}
                      </b>
                      {
                        vaccine.adminDetails.lotNumber
                          ? vaccine.adminDetails.lotNumber
                          : t('NOT_AVAILABLE_INFO')
                      }
                    </VaccineSubtitle>
                  </VaccineDate>
                </div>
              </AccordionDetails>
            ) : (
              <AccordionDetails className={classes.details}>
                <div>
                  <VaccineDate>
                    <VaccineSubtitle alignText="right">
                      <b>
                        {t('CARD_REGISTRY')}:
                        {' '}
                      </b>
                      {vaccine.adminDetails.hcpRegistryNumber}
                    </VaccineSubtitle>
                    <VaccineSubtitle alignText="right">
                      <b>{vaccine.adminDetails.hcpClinicName}</b>
                    </VaccineSubtitle>
                    <VaccineSubtitle alignText="right">
                      <b>
                        {t('CARD_APPLIED_ON')}:
                        {' '}
                      </b>
                      {date}
                    </VaccineSubtitle>
                    <VaccineSubtitle alignText="right">
                      <b>
                        {t('CARD_LOT_NUMBER')}:
                        {' '}
                      </b>
                      {lotNumber}
                    </VaccineSubtitle>
                    <VaccineSubtitle alignText="right">
                      <b>
                        {t('CARD_PRODUCED_BY')}:
                        {' '}
                      </b>
                      {producer}
                    </VaccineSubtitle>
                    <VaccineSubtitle alignText="right">
                      <b>
                        {t('CARD_LOT_NUMBER')}:
                        {' '}
                      </b>
                      {
                        vaccine.adminDetails.lotNumber
                          ? vaccine.adminDetails.lotNumber
                          : t('NOT_AVAILABLE_INFO')
                      }
                    </VaccineSubtitle>
                  </VaccineDate>
                </div>
              </AccordionDetails>
            ) :
            <AccordionDetails className={classes.detailsWithCode}>
              <Box mt={2} mb={1} mx="auto">
                <Typography>
                  {t('VACCINE_NOT_REGISTRATED')}
                </Typography>
              </Box>
            </AccordionDetails >
          }
        </Accordion >
      );
    });
    return vaccinesComponent;
  };

  const vaccinesToRender = createRows();

  const vaccineFiltered = useMemo(() => {
    const lowerSearch = search?.toLowerCase();
    return vaccinesToRender
      .filter((vaccine) => vaccine.key.toLowerCase().includes(lowerSearch));
  }, [vaccinesToRender, search]);

  return (
    vaccineFiltered.length > 0 &&
    <>
      {
        type === 'antibody' &&
        <Subtitle>
          {t('OPTIONALS')}
        </Subtitle>
      }
      <Container>
        {vaccineFiltered}
      </Container>
    </>
  );
};

export default VaccineTable;
